import { ErrorHandler, useEventListener, useGeneratedPrefixedId } from '@flipgrid/flipkit';
import classNames from 'classnames';
import React, { useRef, forwardRef, useImperativeHandle, useEffect, useContext } from 'react';

import GlobalContext from '~/contexts/globalContext';
import transparentTextAreaStyles from '~/styles/components/Utility/TransparentTextArea.css';

export const links = () => [{ rel: 'stylesheet', href: transparentTextAreaStyles }];

type Props = {
  'aria-required'?: boolean | 'true' | 'false';
  'aria-describedby'?: string;
  className?: string;
  enableLineBreak?: boolean;
  label?: string;
  labelClassName?: string;
  labelRole?: 'heading';
  maxLength: number;
  name: string;
  onBlurShortenHeight?: boolean;
  placeholder: string;
  startAtEnd?: boolean;
  titleChanged?: string;
  type?: 'group' | 'topic';
  showError?: boolean;
  error?: string;
  errorClassName?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  id?: string;
};

const TransparentTextArea = forwardRef(
  (
  {
    'aria-required': ariaRequired,
    'aria-describedby': ariaDescribedBy,
    className,
    enableLineBreak,
    id,
    label,
    labelClassName,
    labelRole,
    maxLength = 50,
    name,
    onBlurShortenHeight = true,
    placeholder,
    startAtEnd,
    titleChanged,
    type,
    showError,
    errorClassName,
    error,
    ...rest
  }: Props,
  ref) =>
  {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const globalContext = useContext(GlobalContext);
    const randomId = useGeneratedPrefixedId('transparent-textarea', id);

    // forwardRef and useRef in same component
    useImperativeHandle(ref, () => textareaRef.current);

    // Adjust height of textarea to scrollheight so it dynamically expands with the number of lines
    const handleHeight = () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    };

    // Adjust height of textarea to be short when not being typed in
    const shortenHeight = () => {
      // Added check for onBlurShortenHeight to avoid executing in Topic Form title field case
      if (textareaRef.current && onBlurShortenHeight) {
        textareaRef.current.style.height = 'auto';
      }
    };

    useEffect(() => {
      handleHeight();
    }, []);

    useEffect(() => {
      handleHeight();
    }, [titleChanged]);

    // Start cursor at end
    useEffect(() => {
      if (startAtEnd && textareaRef.current) textareaRef.current.setSelectionRange(maxLength, maxLength);
    }, [maxLength, startAtEnd]);

    // Adjust height on resize and fluid text changes sizes and number of lines changes
    useEventListener('resize', handleHeight);

    // Prevent use of line breaks
    const handleKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') e.preventDefault();
    };

    useEffect(() => {
      if (showError) {
        textareaRef?.current?.focus();
      }
    }, [showError]);

    return (
      <>
        {label && (
        labelRole === 'heading' ?
        <h1 id={`${randomId}-label`} className={classNames('transparentTextArea__label', labelClassName)}>
              {label}
            </h1> :

        <label id={`${randomId}-label`} className={classNames('transparentTextArea__label', labelClassName)}>
              {label}
            </label>)}


        <textarea
          id={id}
          name={name}
          placeholder={placeholder}
          className={classNames('transparentTextArea', { [`-${type}`]: type }, className)}
          onInput={handleHeight}
          onBlur={shortenHeight}
          onFocus={handleHeight}
          onKeyDown={(e) => {
            if (!enableLineBreak) handleKeydown(e);
          }}
          ref={textareaRef}
          maxLength={maxLength}
          rows={1}
          aria-required={ariaRequired}
          aria-describedby={
          ariaDescribedBy + ' ' + (showError ? randomId + '-error' : '') + ' ' + (label ? randomId + '-label' : '')}

          {...rest} />

        <ErrorHandler
          error={error}
          className={errorClassName}
          announceLiveMessage={globalContext.announceLiveMessage}
          showError={showError}
          id={randomId + '-error'} />

      </>);

  }
);

export default TransparentTextArea;